import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { images } from '../../core/contants/images';
import { I18N_NAMESPACES } from '../../core/i18n';
import { useAppLayoutStyles } from '../../core/styles/layout';
import { pageService } from '../../services/page-service';
import { useAppFooterStyles } from './style';

export const AppFooter = React.memo(() => {
  const classes = { ...useAppFooterStyles(), ...useAppLayoutStyles() };
  const { t } = useTranslation([I18N_NAMESPACES.FOOTER]);

  const [pages, setPages] = useState<{ title: string; alias: string }[]>([]);

  useEffect(() => {
    async function getPages() {
      const response = await pageService.getList();
      setPages(response?.data?.result || []);
    }

    getPages();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.firstBlock}>
        <div className={clsx(classes.appContentContainer, classes.firstBlockContentContainer)}>
          <div>
            <div className={classes.title}>{t('Về GDeli.vn')}</div>
            {pages.map((i) => (
              <Link to={`/about/${i.alias}`} key={i.alias}>
                <div className={classes.normalText}>{i.title}</div>
              </Link>
            ))}
          </div>
          <div>
            <div className={classes.title}>{t('Phương thức thanh toán')}</div>
            <div className={classes.paymentMethodContainer}>
              <img src={images.visa} className={classes.paymentMethod} alt="" />
              <img src={images.mastercard} className={classes.paymentMethod} alt="" />
              <img src={images.cash} className={classes.paymentMethod} alt="" />
              <img src={images.momo} className={classes.paymentMethod} alt="" />
            </div>
          </div>
          <div>
            <div className={classes.title}>{t('Truyền thông xã hội')}</div>
            <div className={classes.socialMediaRow}>
              <a className={classes.socialMediaRow} href='https://www.facebook.com/gdeli.vn' target="_blank">
                <img src={images.facebook} className={classes.socialIcon} alt="" />
                <div className={classes.normalText}>{t('Facebook')}</div>
              </a>
            </div>
            <div className={classes.socialMediaRow}>
              <img src={images.youtube} className={classes.socialIcon} alt="" />
              <div className={classes.normalText}>{t('Youtube')}</div>
            </div>
            <a className={classes.socialMediaRow} href='https://thegoldenspoon.com.vn/' target="_blank">
              <img src={images.goldenSpoon} className={classes.socialIcon} alt="" />
              <div className={classes.normalText}>{t('The Golden Spoon')}</div>
            </a>
          </div>
          <div>
            <div className={classes.title}>{t('Hỗ trợ khách hàng')}</div>
            <div className={classes.supportContentContainer}>
              <div className={classes.subTitle}>{t('Hotline')}</div>
              <a href={"tel:19006622"} title={"Hotline"}>
                <div className={classes.normalText}>
                  1900 6622
                </div>
              </a>

              <div className={classes.subTitle}>{t('Email')}</div>
              <a href={"mailto:support.hn@ggg.com.vn"} title={"Email"}>
                <div className={classes.normalText}>support.hn@ggg.com.vn</div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.secondBlock}>
        <div className={clsx(classes.appContentContainer, classes.secondBlockContentContainer)}>
          <div>
            <div className={classes.companyName}>
              {t('Công ty Cổ phần Tập đoàn Golden Gate')}
            </div>
            <div className={classes.normalText}>
              Trụ sở chính: Số 60 Giang Văn Minh, phường Đội Cấn, Quận Ba Đình, Thành phố Hà Nội, Việt Nam{' '}
              <br />
              VPGG: Tầng 6, Tòa nhà Toyota, Số 315 Trường Chinh, P. Khương Mai, Q. Thanh Xuân, TP Hà Nội, Việt Nam.{' '}
              <br />
              Chịu trách nhiệm nội dung: (Bà) Đào Chi Anh{' '}
              <br />
              GPĐK: Số 0102721191 cấp ngày 09/04/2008
            </div>
          </div>
          <div>
            <a href='http://online.gov.vn/Home/WebDetails/65427' target="_blank" title="Đã thông báo bộ công thương">
              <img src={images.verified} className={classes.verifiedLogo} alt="Đã thông báo bộ công thương" />
            </a>
            <div className={classes.copyRightText}>
              {t('© 2011 Golden Gate ., JSC. All rights reserved')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
